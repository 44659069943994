<footer class="main-footer">
  <div class="container">
    <div class="footer-grid">
      <div class="footer-col">
        <h4>Redes Sociais</h4>
        <div class="social-links">
          <a href="https://www.instagram.com/neotec.assistenciatecnica/" target="_blank" rel="noopener">
            <img src="assets/icons/instagram.svg" alt="Instagram">
          </a>
          <a href="https://api.whatsapp.com/send?phone=556135221104&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20solicitar%20um%20or%C3%A7amento" 
             target="_blank" rel="noopener">
            <img src="assets/icons/whatsapp.svg" alt="WhatsApp">
          </a>
        </div>
      </div>

      <div class="footer-col">
        <h4>Contatos</h4>
        <ul>
          <li>
            <a href="tel:+556135221104">+55 (61) 3522-1104</a>
          </li>
          <li>
            <a href="tel:+5561992580149">+55 (61) 99258-0149</a>
          </li>
        </ul>
      </div>

      <div class="footer-col">
        <h4>Endereço</h4>
        <p class="address">
          Av. das Castanheira, 920 <br>
          Ed. Ônix Bloco C loja 02/03/04 Térreo<br>
          Águas Claras, Brasília - DF<br>
          CEP: 71900-100
        </p>
      </div>

      <div class="footer-col">
        <h4>Horário de Atendimento</h4>
        <ul class="hours">
          <li>
            <span>Segunda a Sexta:</span>
            <span>08:00 às 18:00</span>
          </li>
          <li>
            <span>Sábado:</span>
            <span>08:00 às 13:00</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-bottom">
      <p>&copy; 2024 Neotec. Todos os direitos reservados.</p>
    </div>
  </div>
</footer> 