<section class="info-cards">
  <div class="container">
    <div class="info-grid">
      <div class="info-card">
        <div class="image-container">
          <img src="assets/images/support.jpeg" alt="Atendimento completo">
        </div>
        <div class="card-content">
          <h3>Atendimento completo</h3>
          <p>Atendimento para todos os aparelhos domésticos.</p>
        </div>
      </div>

      <div class="info-card">
        <div class="image-container">
          <img src="assets/images/certificado.jpeg" alt="Técnicos certificados">
        </div>
        <div class="card-content">
          <h3>Técnicos certificados</h3>
          <p>Você tem a segurança de ter o seu apareho nas mãos de técnicos certificados.</p>
        </div>
      </div>

      <div class="info-card">
        <div class="image-container">
          <img src="assets/images/atendimento.jpg" alt="Atendimento rápido">
        </div>
        <div class="card-content">
          <h3>Atendimento rápido</h3>
          <p>Sabemos da necessidade de nossos clientes, por isso procuramos resolver seu problema o mais rápido possível.</p>
        </div>
      </div>
    </div>
  </div>
</section> 