<section class="hero">
  <div class="container">
    <div class="hero-content">
      <div class="hero-icon">
        <img src="assets/logo-azul.png" alt="Ícone assistência">
      </div>
      <div class="hero-text">
        <h1>Neotec Assistência Técnica</h1>
        <p>O suporte que você precisa com a experiência que você merece.</p>
      </div>
      <div class="price-tag">
        <span>Limpeza da sua Air fryer a partir de</span>
        <strong>R$ 150,00 no PIX</strong>
      </div>
    </div>
  </div>
</section> 