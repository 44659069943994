<section class="services">
  <div class="container">
    <h2>Serviços:</h2>
    <div class="services-grid">
      <a href="#" class="service-card" (click)="openServiceModal(''); $event.preventDefault()">
        <img src="assets/images/tv.png" alt="Tv">
        <span>TV</span>
        <small>Consultar ></small>
      </a>

      <a href="#" class="service-card" (click)="openServiceModal('AirFryer'); $event.preventDefault()">
        <img src="assets/images/air-fryer.png" alt="AirFryer">
        <span>Air Fryer</span>
        <small>Consultar ></small>
      </a>

      <a href="#" class="service-card" (click)="openServiceModal(''); $event.preventDefault()">
        <img src="assets/images/robo-aspirador.jpg" alt="RoboAspirador">
        <span>Robô Aspirador</span>
        <small>Consultar ></small>
      </a>

      <a href="#" class="service-card" (click)="openServiceModal('Cafeteira'); $event.preventDefault()">
        <img src="assets/images/cafeteira.png" alt="Cafeteira">
        <span>Cafeteira</span>
        <small>Consultar ></small>
      </a>

      <a href="#" class="service-card" (click)="openServiceModal('Computador'); $event.preventDefault()">
        <img src="assets/images/computador.jpg" alt="Computador">
        <span>Notebook/Computador</span>
        <small>Consultar ></small>
      </a>

      <a href="#" class="service-card" (click)="openServiceModal(''); $event.preventDefault()">
        <img src="assets/images/microondas.jpg" alt="Microondas">
        <span>Micro-ondas</span>
        <small>Consultar ></small>
      </a>

      <a href="#" class="service-card" (click)="openServiceModal('Ferro'); $event.preventDefault()">
        <img src="assets/images/ferro.jpg" alt="Ferro">
        <span>Ferro de Passar</span>
        <small>Consultar ></small>
      </a>
      <!-- outros serviços aqui -->
    </div>
  </div>
</section> 