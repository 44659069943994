<section class="faq">
  <div class="container">
    <h2>Perguntas frequentes:</h2>
    <div class="faq-list">
      <details class="faq-item">
        <summary>Preciso realizar backup do meu dispositivo antes de ir na assistência?</summary>
        <p>Sim, recomendamos que você faça o backup de todos os seus dados antes de nos entregar o dispositivo.</p>
      </details>
      <details class="faq-item">
        <summary>Programas de Qualidade são atendidos pela assistência?</summary>
        <p>Todos os programas de qualidade são atendidos na assistência técnica.</p>
      </details>
      <details class="faq-item">
        <summary>Tenho menos de 18 anos, posso solicitar atendimento?</summary>
        <p>Para atendimento de menores de 18 anos, é necessário o acompanhamento do responsável.</p>
      </details>
      <details class="faq-item">
        <summary>Outra pessoa pode retirar o aparelho no meu lugar?</summary>
        <p>Para que outra pessoa faça a retirada do aparelho, será necessário enviar uma mensagem para o whatsapp da assistência técnica, descrevendo o nome de quem irá retirar, numero do documento e o relato autorizando (a mensagem que irá enviar a autorização precisa ser o mesmo do cadastro, informado no primeiro atendimento).</p>
      </details>
      <details class="faq-item">
        <summary>O que é necessário para realizar o atendimento?</summary>
        <p>Para realizar o atendimento, é necessário ter o aparelho na mãos, o documento de identificação e ser maior de 18 anos.</p>
      </details>
    </div>
  </div>
</section> 