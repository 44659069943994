<section class="scheduling-status">
  <div class="container">
    <div class="grid-2">
      <div class="card">
        <h3>Agende seu atendimento</h3>
        <p>Agende seu atendimento para um dia e horário personalizados.</p>
        <a href="https://api.whatsapp.com/send?phone=556135221104&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20solicitar%20um%20or%C3%A7amento" 
           target="_blank" 
           rel="noopener" 
           class="button">Agendar</a>
      </div>
      <div class="card">
        <h3>Status de reparo</h3>
        <p>Consulte o número do reparo fornecido pelo técnico para acompanhar o atendimento.</p>
        <a href="https://api.whatsapp.com/send?phone=556135221104&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20consultar%20o%20status%20do%20meu%20reparo" 
           target="_blank" 
           rel="noopener" 
           class="button">Consultar</a>
      </div>
    </div>
  </div>
</section> 