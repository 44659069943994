import { Component, OnInit, OnDestroy, inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-chat',
  template: '',
  standalone: true
})
export class ChatComponent implements OnInit, OnDestroy {
  private platformId = inject(PLATFORM_ID);

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const script = document.createElement('script');
      script.src = 'https://app.rosana.io/widget/3D79248137A0853207429A928F17E063/float.js';
      script.async = true;
      
      // Verifica se o script já existe na página
      const existingScript = document.querySelector(`script[src="${script.src}"]`);
      if (!existingScript) {
        document.body.appendChild(script);
      }
    }
  }

  ngOnDestroy() {
    // Removido o evento beforeunload para manter o chat
    // O chat só será removido quando a aba for fechada
  }
} 