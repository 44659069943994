<section class="testimonials">
  <div class="container">
    <h2>O que dizem nossos clientes:</h2>
    <div class="testimonials-grid">
      <div class="testimonial-card">
        <img src="https://www.iplace.com.br/file/general/hotsite-assistencia-tecnica-avaliacao-memoji-1.png" alt="Avatar" class="avatar">
        <h4>Bernardo José da Silva</h4>
        <p class="review">O atendimento foi excelente, muito bem recebido e com preço justo. Recomendo a todos!</p>
      </div>
      <div class="testimonial-card">
        <img src="https://www.iplace.com.br/file/general/hotsite-assistencia-tecnica-avaliacao-memoji-5.png" alt="Avatar" class="avatar">
        <h4>Raimundo Jose da Silva</h4>
        <p class="review">Vocês estão de parabéns, foram além das minhas expectativas.</p>
      </div>
      <div class="testimonial-card">
        <img src="https://www.iplace.com.br/file/general/hotsite-assistencia-tecnica-avaliacao-memoji-2.png" alt="Avatar" class="avatar">
        <h4>Yngrid Alves da Silva</h4>
        <p class="review">O atendimento da loja e funcionários foram excelentes e o prazo informado foi cumprido, fiquei muito satisfeita.</p>
      </div>
    </div>
  </div>
</section> 