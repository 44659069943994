import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  private serviceSubject = new BehaviorSubject<string>('');

  isOpen$ = this.isOpenSubject.asObservable();
  service$ = this.serviceSubject.asObservable();

  openModal(service?: string) {
    this.serviceSubject.next(service || '');
    this.isOpenSubject.next(true);
    document.body.style.overflow = 'hidden';
  }

  closeModal() {
    this.isOpenSubject.next(false);
    document.body.style.overflow = 'auto';
  }
} 