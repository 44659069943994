<div class="modal" [class.modal-open]="isOpen$ | async" (click)="closeModal()">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <div class="modal-header">
      <h2>{{service$ | async}}</h2>
      <button class="close-button" (click)="closeModal()">×</button>
    </div>
    <div class="modal-body">
      <div class="service-prices" *ngIf="(service$ | async) === 'AirFryer'">
        <div class="price-item">
          <h3>Reparos em puxador do cesto:</h3>
          <p class="price">a partir de R$ 60,00</p>
        </div>
        <div class="price-item">
          <h3>Limpeza completa:</h3>
          <p class="price">R$ 160,00 à vista</p>
          <p class="installments">ou R$ 160,00 em até 2x</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em modelo simples:</h3>
          <p class="price">R$ 170,00 à vista</p>
          <p class="installments">ou R$ 170,00 em até 2x</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em modelo Walita Analógica:</h3>
          <p class="price">R$ 290,00 à vista</p>
          <p class="installments">ou R$ 290,00 em até 2x</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em modelo Philco Forninho:</h3>
          <p class="price">R$ 340,00 à vista</p>
          <p class="installments">ou R$ 340,00 em até 3x</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em modelo Walita Digital:</h3>
          <p class="price">R$ 490,00 à vista</p>
          <p class="installments">ou R$ 490,00 em até 3x</p>
        </div>
      </div>

      <div class="service-prices" *ngIf="(service$ | async) === 'Ferro'">
        <div class="price-item">
          <h3>Manutenção em Ferro de Passar Simples:</h3>
          <p class="price">R$ 50,00</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em Ferro de Passar a Vapor:</h3>
          <p class="price">R$ 90,00 à vista</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em Ferro de Passar Polishop Perfect Care:</h3>
          <p class="price">R$ 480,00 à vista</p>
          <p class="installments">ou R$ 480,00 em até 3x</p>
        </div>

        <div class="default-message">
            <p class="message">Para garantir uma informação exata de preços, é necessário realizar uma análise técnica em loja.</p>
            <div class="action-buttons">
              <a href="https://api.whatsapp.com/send?phone=556135221104&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20solicitar%20um%20or%C3%A7amento" 
                 target="_blank" 
                 rel="noopener" 
                 class="button whatsapp">
                <i class="whatsapp-icon"></i> WhatsApp
              </a>
            </div>
          </div>
      </div>

      <div class="service-prices" *ngIf="(service$ | async) === 'Cafeteira'">
        <div class="price-item">
          <h3>Manutenção em Cafeteira Simples:</h3>
          <p class="price">R$ 90,00</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em Cafeteira Expresso:</h3>
          <p class="price">R$ 210,00 à vista</p>
          <p class="installments">ou R$ 210,00 em até 2x</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em Cafeteira Tipo Máquina:</h3>
          <p class="price">R$ 290,00 à vista</p>
          <p class="installments">ou R$ 290,00 em até 2x</p>
        </div>

        <div class="default-message">
            <p class="message">Para garantir uma informação exata de preços, é necessário realizar uma análise técnica em loja.</p>
            <div class="action-buttons">
              <a href="https://api.whatsapp.com/send?phone=556135221104&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20solicitar%20um%20or%C3%A7amento" 
                 target="_blank" 
                 rel="noopener" 
                 class="button whatsapp">
                <i class="whatsapp-icon"></i> WhatsApp
              </a>
            </div>
          </div>
      </div>

      <div class="service-prices" *ngIf="(service$ | async) === 'Computador'">
        <div class="price-item">
          <h3>Formatação sem backup:</h3>
          <p class="price">R$ 150,00</p>
        </div>
        <div class="price-item">
          <h3>Formatação com backup:</h3>
          <p class="price">R$ 200,00</p>
          <p class="installments">ou R$ 200,00 em até 2x</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em dobradiças e carcaças Notebook:</h3>
          <p class="price">R$ 200,00 à vista</p>
          <p class="installments">ou R$ 200,00 em até 2x</p>
        </div>
        <div class="price-item">
          <h3>Manutenção em Cafeteira Tipo Máquina:</h3>
          <p class="price">R$ 290,00 à vista</p>
          <p class="installments">ou R$ 290,00 em até 2x</p>
        </div>

        <div class="default-message">
            <p class="message">Para garantir uma informação exata de preços, é necessário realizar uma análise técnica em loja.</p>
            <div class="action-buttons">
              <a href="https://api.whatsapp.com/send?phone=556135221104&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20solicitar%20um%20or%C3%A7amento" 
                 target="_blank" 
                 rel="noopener" 
                 class="button whatsapp">
                <i class="whatsapp-icon"></i> WhatsApp
              </a>
            </div>
          </div>
      </div>

      <div class="default-message" *ngIf="!(service$ | async)">
        <p class="message">Para garantir uma informação exata de preços, é necessário realizar uma análise técnica em loja.</p>
        <div class="action-buttons">
          <a href="https://api.whatsapp.com/send?phone=556135221104&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20solicitar%20um%20or%C3%A7amento" 
             target="_blank" 
             rel="noopener" 
             class="button whatsapp">
            <i class="whatsapp-icon"></i> WhatsApp
          </a>
        </div>
      </div>
    </div>
  </div>
</div> 