import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from '../../shared/services/modal.service';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  isOpen$: any;
  service$: any;

  constructor(private modalService: ModalService) {
    this.isOpen$ = this.modalService.isOpen$;
    this.service$ = this.modalService.service$;
  }

  closeModal() {
    this.modalService.closeModal();
  }
} 