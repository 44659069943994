import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { ServicesComponent } from './components/services/services.component';
import { ModalComponent } from './components/modal/modal.component';
import { HeroComponent } from './components/hero/hero.component';
import { SchedulingComponent } from './components/scheduling/scheduling.component';
import { InfoCardsComponent } from './components/info-cards/info-cards.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { ChatComponent } from './components/chat/chat.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    HeroComponent,
    ServicesComponent,
    SchedulingComponent,
    InfoCardsComponent,
    TestimonialsComponent,
    FaqComponent,
    FooterComponent,
    ModalComponent,
    ChatComponent
  ],
  template: `
    <app-header></app-header>
    <main>
      <app-hero></app-hero>
      <app-services></app-services>
      <app-scheduling></app-scheduling>
      <app-info-cards></app-info-cards>
      <app-testimonials></app-testimonials>
      <app-faq></app-faq>
    </main>
    <app-footer></app-footer>
    <app-modal></app-modal>
    <app-chat></app-chat>
  `
})
export class AppComponent {
  title = 'LandingPageNeotec';
}
