<header>
  <nav class="main-nav">
    <div class="container">
      <div class="logo">
        <!--<img src="/assets/logo-azul.png" alt="Neotec">-->
      </div>
      <div class="nav-menu">
        <!--<a href="#">Serviços</a>
        <a href="#">Agendamento</a>
        <a href="#">Login</a>-->
      </div>
    </div>
  </nav>
</header> 